import React, { FC, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { Layout as LayoutAntd, Grid } from 'antd';
import { Link } from 'react-router-dom';

import { UserAvatar } from 'src/features/user/components/UserAvatar';
import { useAuth } from 'src/hooks/useAuth/useAuth';
import {
  TrainingMenu,
  TrainingReleaseDateMessage,
} from 'src/features/learning/components';
import { updateCollapsedSiderPreferenceRequest } from 'src/store/actions/user';
import { THIconMenuCollapse, THIconMenuExpand } from 'src/theme/media';
import { remConvert } from 'src/theme/utils';
import { AssessmentCompletedMessage } from 'src/features/risk-assessment/components/AssessmentCompletedMessage';
import { useAssessment } from 'src/hooks/useAssessment';
import { useTraining } from 'src/hooks/useTraining';
import { useIsTrainingRoute } from 'src/hooks/useIsTrainingRoute';
import { getLatestDocumentFromState } from 'src/store/selectors';

import { SidebarMenu } from './SidebarMenu';
import { Logo } from './Logo';
import { ArrowIcon, LayoutOverlay, Sider } from './Styles';

const { Content } = LayoutAntd;

const GeneralLayoutContainer = styled(LayoutAntd)`
  background-color: ${({ theme }): string => theme.colors.mariner.main};
  overflow: hidden;
  min-width: 1000px;
`;

const SiteLayout = styled(LayoutAntd)<{ isCollapsed?: boolean }>`
  overflow: hidden;
  min-width: ${({ isCollapsed }): string => (isCollapsed ? '930px' : '760px')};
  margin: ${remConvert(24)} ${remConvert(24)} ${remConvert(0)} ${remConvert(0)};
  background-color: ${({ theme }): string => theme.colors.grayscale.lightGray};
  border-radius: ${remConvert(8)};
  height: calc(100vh - 48px);
  position: relative;
  .ant-layout-content {
    margin: ${remConvert(24)} ${remConvert(16)} ${remConvert(0)};
    padding: ${remConvert(0)} ${remConvert(12)};
    overflow-y: scroll;
  }
`;

export interface MainLayoutProps {
  children: React.ReactNode;
}

const { useBreakpoint } = Grid;

export const MainLayout: FC<MainLayoutProps> = ({ children }) => {
  const { isUser, user, userLoader, is2FAauthenticated } = useAuth();
  const [canShowTrainingReleaseMessage, setCanShowTrainingReleaseMessage] =
    useState<boolean>(false);
  const [isCollapsed, setIsCollapsed] = useState(() => {
    if (user.id === 0 || !is2FAauthenticated) {
      return true;
    }
    return user.preference.collapseSideBar;
  });
  const { isAssessmentOverlay } = useAssessment();
  const { isTrainingPeriodReleaseOverlay, currentTrainingPeriod } =
    useTraining();
  const isTrainingRoute = useIsTrainingRoute();
  const { assessment } = useAssessment();
  const latestDocument = useSelector(getLatestDocumentFromState);
  const dispatch = useDispatch();
  const screens = useBreakpoint();
  const mediumBp = screens['xl'];
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isTrainingPeriodReleaseOverlay && currentTrainingPeriod) {
      setCanShowTrainingReleaseMessage(isTrainingPeriodReleaseOverlay);
    }
  }, [isTrainingPeriodReleaseOverlay, currentTrainingPeriod]);

  useEffect(() => {
    if (contentRef.current) {
      contentRef.current.scrollTop = 0;
    }
  }, [children, isAssessmentOverlay, canShowTrainingReleaseMessage]);

  const manualToggleSider = () => {
    setIsCollapsed(!isCollapsed);
    toggleSider();
  };

  const toggleSider = () => {
    dispatch(
      updateCollapsedSiderPreferenceRequest(!user.preference.collapseSideBar),
    );
  };

  useEffect(() => {
    if (!mediumBp) {
      if (isCollapsed) {
        return;
      }
      setIsCollapsed(true);
      toggleSider();
    }
  }, [mediumBp]);

  return (
    <GeneralLayoutContainer>
      <Sider
        collapsible
        trigger={null}
        collapsed={isCollapsed}
        collapsedWidth={70}
        width={240}
      >
        <div className="row logo">
          <Link to="/dashboard">
            <Logo />
          </Link>
        </div>
        <SidebarMenu collapsed={isCollapsed} />
        {!userLoader.loading && isCollapsed && (
          <div className="row expand-toggle">
            <ArrowIcon src={THIconMenuExpand} onClick={manualToggleSider} />
          </div>
        )}
        <div className="row avatar">
          {isUser && (
            <UserAvatar
              user={{
                name: user.name,
                avatar: user.avatarImg,
                email: user.email,
                role: user.role,
                id: user.id,
                company: user.company?.name,
              }}
              full
              linkToProfile
            />
          )}
          {!userLoader.loading && !isCollapsed && (
            <div
              className={`expand-toggle collapse ${!isUser ? 'hidden-avatar' : ''}`}
            >
              <ArrowIcon src={THIconMenuCollapse} onClick={manualToggleSider} />
            </div>
          )}
        </div>
      </Sider>
      <SiteLayout isCollapsed={isCollapsed}>
        {isAssessmentOverlay && (
          <LayoutOverlay>
            <AssessmentCompletedMessage
              assessment={assessment}
              document={latestDocument}
            />
          </LayoutOverlay>
        )}
        {canShowTrainingReleaseMessage && (
          <LayoutOverlay>
            <TrainingReleaseDateMessage
              releaseDate={currentTrainingPeriod?.releaseDate}
            />
          </LayoutOverlay>
        )}
        {isTrainingRoute && <TrainingMenu />}
        <Content ref={contentRef}>{children}</Content>
      </SiteLayout>
    </GeneralLayoutContainer>
  );
};
