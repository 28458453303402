/* eslint-disable no-console */
import React, { useEffect, useRef, useState, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { Form } from 'antd';
import { SidePanel as SidePanelBase } from 'src/components/SidePanel';
import {
  RootState,
  SelectedDocumentState,
  isAssessmentReportDocument,
  ThreadListingFilter,
  AssessmentReportDocument,
  InterviewState,
  FormState,
  DocumentStatus,
} from 'src/store/types';
import {
  getSelectedDocument,
  getThreadsByQuestionId,
} from 'src/store/selectors';
import { DialogListing } from 'src/components/Dialog';
import { CreateCommentForm } from 'src/features/risk-assessment/components/CreateCommentForm';
import { useAuth } from 'src/hooks/useAuth/useAuth';
import { commentDeleteRequest } from 'src/store/actions/comment';
import { remConvert } from 'src/theme/utils';

const Column = styled.div`
  display: flex;
`;

const SidePanel = styled(SidePanelBase)<{ isNewComment: boolean }>`
  overflow-y: hidden;
  height: ${({ isNewComment }) => (isNewComment ? 'max-content' : 'auto')};
  .content {
    padding: 0;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
`;

const FilterContainer = styled.div`
  display: flex;
  font-size: 15px;
  margin-top: 16px;
  margin-left: 30px;

  h5 {
    cursor: pointer;
    font-weight: ${({ theme }) => theme.fontWeights.bold};
    font-size: 14px;
    line-height: ${remConvert(19)};
    color: ${({ theme }) => theme.colors.darkBlue.main};
    margin: 0 0 ${remConvert(20)} 0;
    padding: 0 ${remConvert(10)};
    border-left: 1px solid #c0c0c0;

    &.selected {
      color: ${({ theme }) => theme.colors.deepBlue.main};
    }

    &:first-of-type {
      border-left-width: 0;
      padding-left: 0;
    }
  }
`;

interface Props {
  assessmentId?: number;
  documentDraftId?: number;
  companyId: number;
  documentId: number;
  threadId?: number;
  selectedQuestionId?: number;
  selectedCommentId?: number;
  replyingToCommentId?: number;
  refKey?: string;
  areThreadsWithoutQuestion?: boolean;
  writeCommentPanelVisible: boolean;
  formList?: FormState[];
  onClose: () => void;
  setSelectedQuestionId?: (questionId?: number) => void;
  setSelectedThreadId: (threadId?: number) => void;
  setSelectedLine?: (line?: number) => void;
  setSelectedRefKey?: (refKey?: string) => void;
  handleStartNewDiscussion: () => void;
  handleReply: (parentId: number, replyingToId: number) => void;
  onSave?: () => void;
  onClosePanel: () => void;
  documentDraftStatus?: DocumentStatus;
  refKeysByLine?: { [k: number]: string };
}

export const SideDialogBox = memo(
  ({
    assessmentId,
    documentDraftId,
    companyId,
    documentId,
    threadId,
    selectedQuestionId,
    selectedCommentId,
    replyingToCommentId,
    refKey,
    areThreadsWithoutQuestion,
    writeCommentPanelVisible,
    formList,
    onClose,
    setSelectedQuestionId,
    setSelectedThreadId,
    handleStartNewDiscussion,
    handleReply,
    setSelectedLine,
    setSelectedRefKey,
    onSave,
    onClosePanel,
    documentDraftStatus,
    refKeysByLine,
  }: Props) => {
    const { isAdmin } = useAuth();
    const dispatch = useDispatch();
    const [dialogFilter, setDialogFilter] = useState(ThreadListingFilter.all);
    const [visibleQuestionIds, setVisibleQuestionIds] = useState<number[]>([]);

    const selectedDocument: SelectedDocumentState =
      useSelector(getSelectedDocument);
    const threadsByQuestionId = useSelector((state: RootState) =>
      getThreadsByQuestionId(state),
    );
    const [commentForm] = Form.useForm();
    const newCommentFormRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
      if (documentDraftId) return;
      const { file } = selectedDocument.document;

      if (file && isAssessmentReportDocument(file)) {
        const { interviews } = file as AssessmentReportDocument;

        setVisibleQuestionIdsFromInterviews(interviews);
      }
    }, [documentDraftId, selectedDocument.document]);

    const questionsIdsByThreadId =
      !documentDraftId &&
      threadsByQuestionId &&
      Object.keys(threadsByQuestionId).reduce((resMap, questionId) => {
        const qId = Number(questionId);
        const thread = threadsByQuestionId[qId];
        if (!thread) return resMap;
        return {
          ...resMap,
          [thread.id]: qId,
        };
      }, {});

    const handleCommentDelete = (commentId: number) => {
      dispatch(commentDeleteRequest(commentId, assessmentId, documentDraftId));
    };

    const setVisibleQuestionIdsFromInterviews = (
      interviews: InterviewState[],
    ) => {
      const resIds: number[] = interviews.reduce((ids, interview) => {
        return ids.concat(interview.visibleQuestionsIds || []);
      }, [] as number[]);

      setVisibleQuestionIds(resIds);
    };

    const handleCommentSave = () => {
      commentForm.submit();
      onSave?.();
    };

    const canWriteFreeComments = () => {
      return areThreadsWithoutQuestion || !!selectedQuestionId;
    };

    return (
      <Column>
        <SidePanel
          isNewComment={writeCommentPanelVisible && !selectedCommentId}
          onConfirm={handleStartNewDiscussion}
          fixed
          confirmEnabled={
            canWriteFreeComments() &&
            documentDraftStatus !== DocumentStatus.CLOSED
          }
          hideFooter={writeCommentPanelVisible}
          confirmLabel="Write a comment"
        >
          <FilterContainer>
            <h5
              onClick={() => setDialogFilter(ThreadListingFilter.all)}
              className={
                dialogFilter === ThreadListingFilter.all ? 'selected' : ''
              }
            >
              Comments
            </h5>
            {isAdmin && (
              <h5
                onClick={() => setDialogFilter(ThreadListingFilter.admin)}
                className={
                  dialogFilter === ThreadListingFilter.admin ? 'selected' : ''
                }
              >
                Admin Notes
              </h5>
            )}
            <h5
              onClick={() => setDialogFilter(ThreadListingFilter.files)}
              className={
                dialogFilter === ThreadListingFilter.files ? 'selected' : ''
              }
            >
              Files
            </h5>
          </FilterContainer>
          <DialogListing
            assessmentId={assessmentId}
            documentDraftId={documentDraftId}
            threadId={threadId}
            formList={formList}
            filter={dialogFilter}
            setSelectedQuestionId={setSelectedQuestionId}
            setSelectedThreadId={setSelectedThreadId}
            handleReply={handleReply}
            handleDelete={handleCommentDelete}
            questionIds={documentDraftId ? undefined : questionsIdsByThreadId}
            visibleQuestionIds={
              documentDraftId ? undefined : visibleQuestionIds
            }
            setSelectedLine={setSelectedLine}
            setSelectedRefKey={setSelectedRefKey}
            areThreadsWithoutQuestion={areThreadsWithoutQuestion}
            refKeysByLine={refKeysByLine}
          />
          {writeCommentPanelVisible && (
            <CreateCommentForm
              ref={newCommentFormRef}
              form={commentForm}
              assessmentId={assessmentId}
              threadId={threadId}
              questionId={selectedQuestionId}
              parentId={selectedCommentId}
              replyingToId={replyingToCommentId}
              onSavePress={handleCommentSave}
              onClose={onClose}
              documentDraftId={documentDraftId}
              refKey={refKey}
              companyId={companyId}
              documentId={documentId}
              closePanel={onClosePanel}
            />
          )}
        </SidePanel>
      </Column>
    );
  },
);
