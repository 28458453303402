import moment from 'moment';
import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';

import {
  AUTH_2FA_REQUEST,
  Auth2FARequestAction,
  CLEAR_2FA_REQUEST,
  Clear2FARequestAction,
  CompanyUserRole,
  GET_2FA_CODE_REQUEST,
  GET_USERS_REQUEST,
  GET_USER_ONBOARDING_REQUEST,
  GetUsersRequestAction,
  GetUserOnboardingRequestAction,
  IMPERSONATE_REQUEST,
  ImpersonateRequestAction,
  LOGIN_CHECK,
  LOGIN_REQUEST,
  LOGOUT_REQUEST,
  LoginRequestAction,
  MULTIPLE_USERS_SAVE_REQUEST,
  MultipleUsersCreationResponse,
  MultipleUsersSaveRequestAction,
  PaginatedUsers,
  RESET_PASSWORD_REQUEST,
  ResetPasswordRequestAction,
  TURN_ON_2FA_REQUEST,
  TurnOn2FARequestAction,
  USER_COMPLETE_ONBOARDING_REQUEST,
  USER_DELETE_REQUEST,
  USER_SAVE_REQUEST,
  USER_UPDATE_COLLAPSED_SIDER_PREFERENCE_REQUEST,
  USER_UPDATE_REQUEST,
  User,
  UserCompleteOnboardingRequestAction,
  UserDeleteRequestAction,
  UserFromResponse,
  UserSaveRequestAction,
  UserUpdateCollapsedSiderPreferenceRequestAction,
  UserUpdateRequestAction,
} from 'src/store/types/user';

import { api } from 'src/api/services/ThApi';
import { getErrorMessages } from 'src/features/user/components/FormCreateMultipleUsers';
import { confirmDialog, success } from 'src/shared/utils';
import actions from 'src/store/actions';

let sessionTimeout: NodeJS.Timeout;
let expiredSessionTimeout: NodeJS.Timeout;

const setExpirationDate = () => {
  const expirationDate = moment().add(24, 'hours').toDate();
  sessionStorage.setItem('sessionExpireAt', JSON.stringify(expirationDate));
};

const refreshToken = async () => {
  await api.refreshToken();
  clearTimeout(sessionTimeout);
  clearTimeout(expiredSessionTimeout);
  setExpirationDate();
  setSessionExpireMessage();
};

const setSessionExpireMessage = () => {
  const sessionExpireAt = sessionStorage.getItem('sessionExpireAt');
  if (!sessionExpireAt) return;
  const expirationDate = new Date(JSON.parse(sessionExpireAt));
  const timeLeftToNotify =
    moment(expirationDate).subtract(30, 'minutes').toDate().getTime() -
    new Date().getTime();
  clearTimeout(sessionTimeout);
  clearTimeout(expiredSessionTimeout);
  sessionTimeout = setTimeout(() => {
    confirmDialog({
      text: 'Session will expire soon',
      okText: 'Extend session',
      onOk: refreshToken,
      onCancel: () => {
        const timeLeftToExpire =
          expirationDate.getTime() - new Date().getTime();
        expiredSessionTimeout = setTimeout(() => {
          confirmDialog({
            text: 'Session has expired',
            okText: 'Extend session',
            onOk: refreshToken,
            onCancel: () => {
              logout();
              window.location.replace('/login');
            },
          });
        }, timeLeftToExpire);
      },
    });
  }, timeLeftToNotify);
};

export const login = function* async({
  email,
  password,
}: LoginRequestAction): Generator {
  try {
    const user = yield call(api.login, email, password);
    setExpirationDate();
    setSessionExpireMessage();
    yield put(actions.loginSuccess(user as User));
  } catch (err) {
    yield put(actions.loginError('Failed to login', err as Error));
  }
};

export const impersonate = function* async({
  email,
}: ImpersonateRequestAction): Generator {
  try {
    const user = yield call(api.impersonate, email);
    setExpirationDate();
    setSessionExpireMessage();
    yield put(actions.impersonateSuccess(user as User));
  } catch (err) {
    yield put(
      actions.impersonateError(
        `Impersonate to ${email} user failed`,
        err as Error,
      ),
    );
  }
};

export const getUserDetails = function* async(): Generator {
  try {
    const user = yield call(api.getUserDetails);
    setSessionExpireMessage();
    yield put(actions.loginSuccess(user as User));
    yield put(actions.loginCheckCompleted());
  } catch (err) {
    // We don't need any error here, if it fails nothing should happen.
    yield put(actions.loginCheckCompleted());
  }
};

export const logout = function* async(): Generator {
  try {
    sessionStorage.removeItem('sessionExpireAt');
    clearTimeout(sessionTimeout);
    clearTimeout(expiredSessionTimeout);
    yield call(api.logout);
    yield put(actions.logoutSuccess());
  } catch (err) {
    yield put(actions.logoutError('Failed to logout', err as Error));
  }
};

export const resetPassword = function* async({
  username,
}: ResetPasswordRequestAction): Generator {
  try {
    yield call(api.resetPassword, username);
    yield put(actions.resetPasswordSuccess());
    success('User password has been reset.');
  } catch (err) {
    yield put(actions.resetPasswordError('Something went wrong', err as Error));
  }
};

export const updateUser = function* async({
  userInput,
  authToken,
  updateUserData,
}: UserUpdateRequestAction): Generator {
  try {
    const user = yield call(api.userUpdate, userInput, authToken);
    yield put(actions.userUpdateSuccess(user as User));
    if (updateUserData) {
      yield put(actions.loginCheck());
    }
  } catch (err) {
    yield put(actions.userUpdateError('Failed to update user', err as Error));
  }
};

export const saveUser = function* async({
  user,
}: UserSaveRequestAction): Generator {
  try {
    let newUser;
    if (user.role !== CompanyUserRole.admin) {
      newUser = yield call(api.saveCompanyUser, user);
    } else {
      newUser = yield call(api.saveGlobalUser, user);
    }

    yield put(actions.userSaveSuccess(newUser as UserFromResponse));
    yield put(actions.addUserToList(newUser as UserFromResponse));
  } catch (err) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    const errorMessage =
      (err as unknown as any).response?.data?.message || 'Failed to save user';
    yield put(actions.userSaveError(errorMessage, err as Error));
  }
};

export const deleteUser = function* async({
  id,
}: UserDeleteRequestAction): Generator {
  try {
    yield call(api.deleteUser, id);
    yield put(actions.userDeleteSuccess(id));
  } catch (err) {
    yield put(actions.userDeleteError('Failed to delete user', err as Error));
  }
};

export const get2FACode = function* async(): Generator {
  try {
    const code = yield call(api.get2FACode);
    yield put(actions.get2FACodeSuccess(code as string));
  } catch (err) {
    yield put(actions.Get2FACodeError('Failed to get 2FA code', err as Error));
  }
};

export const turnOn2FA = function* async({
  code,
  authMethod,
  dialCode,
  phoneNumber,
}: TurnOn2FARequestAction): Generator {
  try {
    const response = yield call(
      api.turnOn2FA,
      code,
      authMethod,
      dialCode,
      phoneNumber,
    );
    yield put(
      actions.turnOn2FASuccess(
        (response as any).isTwoFactorAuthenticationEnabled as boolean,
        (response as any).user,
      ),
    );
  } catch (err) {
    yield put(actions.turnOn2FAError('Failed to turn on 2FA', err as Error));
  }
};

export const auth2FA = function* async({
  code,
  authMethod,
}: Auth2FARequestAction): Generator {
  try {
    const user = yield call(api.auth2FA, code, authMethod);
    yield put(actions.auth2FASuccess(user as User));
  } catch (err) {
    yield put(actions.auth2FAError('Failed to auth 2FA', err as Error));
  }
};

export const multipleUsersSave = function* async({
  users,
}: MultipleUsersSaveRequestAction): Generator {
  try {
    const res = yield call(api.saveMultipleUsers, users);
    const { data: createdUsers, errors } = res as MultipleUsersCreationResponse;
    yield put(actions.multipleUsersSaveSuccess(createdUsers));
    if (errors.length > 0) {
      const errorMessages = [
        `Created Users: ${createdUsers.length}/${users.length}.`,
        ...getErrorMessages(errors),
      ];
      yield put(
        actions.showErrorMessage(
          errorMessages,
          errors.map(String.toString),
        ) as any, //TODO remove any
      );
    }
  } catch (err) {
    yield put(
      actions.multipleUsersSaveError('Failed to save users', err as Error),
    );
  }
};

export const clear2FA = function* async({
  userId,
}: Clear2FARequestAction): Generator {
  try {
    yield call(api.clear2FAFromUser, userId);
    yield put(actions.clear2FASuccess(userId));
    success('User 2FA has been cleared.');
  } catch (err) {
    yield put(actions.clear2FAError('Failed to clear 2FA', err as Error));
  }
};

export const getPaginatedUsers = function* async({
  page,
  pageSize,
  filter,
}: GetUsersRequestAction): Generator {
  try {
    const users = yield call(api.getUsers, page, pageSize, filter);
    yield put(actions.getUsersSuccess(users as PaginatedUsers));
  } catch (err) {
    yield put(actions.getUsersError('Failed to get users', err as Error));
  }
};

export const userUpdateCollapsedSiderPreference = function* async({
  collapsedSider,
}: UserUpdateCollapsedSiderPreferenceRequestAction): Generator {
  try {
    yield call(api.updateCollapsedSider, collapsedSider);
    yield put(actions.updateCollapsedSiderPreferenceSuccess(collapsedSider));
  } catch (err) {
    yield put(
      actions.updateCollapsedSiderPreferenceError(
        'Failed to update user preferences',
        err as Error,
      ),
    );
  }
};

export const getUserOnboardingInfo = function* async({
  id,
}: GetUserOnboardingRequestAction): Generator {
  try {
    const user = yield call(api.getUserOnboarding, id);
    yield put(actions.getUserOnboardingSuccess(user as UserFromResponse));
  } catch (err) {
    yield put(
      actions.getUserOnboardingError('Failed to retrieve user onboarding info'),
    );
  }
};

export const completeUserOnboarding = function* async({
  id,
}: UserCompleteOnboardingRequestAction): Generator {
  try {
    const user = yield call(api.completeUserOnboarding, id);
    yield put(actions.completeUserOnboardingSuccess(user as UserFromResponse));
  } catch (err) {
    yield put(
      actions.completeUserOnboardingError(
        'Failed to complete the user onboarding',
        err as Error,
      ),
    );
  }
};

export default function* (): Generator {
  return [
    yield takeEvery(LOGIN_REQUEST, login),
    yield takeEvery(IMPERSONATE_REQUEST, impersonate),
    yield takeEvery(LOGOUT_REQUEST, logout),
    yield takeLatest(LOGIN_CHECK, getUserDetails),
    yield takeEvery(RESET_PASSWORD_REQUEST, resetPassword),
    yield takeEvery(USER_UPDATE_REQUEST, updateUser),
    yield takeLatest(USER_SAVE_REQUEST, saveUser),
    yield takeEvery(GET_2FA_CODE_REQUEST, get2FACode),
    yield takeEvery(TURN_ON_2FA_REQUEST, turnOn2FA),
    yield takeEvery(AUTH_2FA_REQUEST, auth2FA),
    yield takeLatest(MULTIPLE_USERS_SAVE_REQUEST, multipleUsersSave),
    yield takeEvery(CLEAR_2FA_REQUEST, clear2FA),
    yield takeEvery(USER_DELETE_REQUEST, deleteUser),
    yield takeEvery(GET_USERS_REQUEST, getPaginatedUsers),
    yield takeEvery(
      USER_UPDATE_COLLAPSED_SIDER_PREFERENCE_REQUEST,
      userUpdateCollapsedSiderPreference,
    ),
    yield takeEvery(GET_USER_ONBOARDING_REQUEST, getUserOnboardingInfo),
    yield takeEvery(USER_COMPLETE_ONBOARDING_REQUEST, completeUserOnboarding),
  ];
}
