import styled from 'styled-components';
import {
  Progress as ProgressAntD,
  ProgressProps as ProgressPropsAntD,
} from 'antd';
import { remConvert } from 'src/theme/utils';
import { centeredFlex } from 'src/theme';

type ProgressProps = ProgressPropsAntD;

export const Progress = styled(ProgressAntD)<ProgressProps>`
  ${centeredFlex}

  .ant-progress-inner {
    background: ${({ theme }) => theme.colors.alto.secondary};
  }

  .ant-progress-bg {
    background: ${({ theme }) => theme.colors.astral.main};
  }

  .ant-progress-inner,
  .ant-progress-bg {
    height: ${(props) =>
      props.size === 'default' ? `${remConvert(10)}!important ` : ''};
  }

  .ant-progress-text {
    font-family: ${({ theme }) => theme.fontFamilies.primary};
    font-style: ${({ theme }) => theme.fontStyles.normal};
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    color: ${({ theme }) => theme.colors.grayscale.preDark};
    line-height: ${(props) =>
      props.size === 'default' ? `${remConvert(41)}` : ''};
    font-size: ${(props) =>
      props.size === 'default' ? `${remConvert(18)}` : ''};
    margin-left: 15px;
    ${centeredFlex}
    white-space: nowrap;
  }
`;
