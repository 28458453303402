/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-empty-interface */
import axios, { AxiosError, AxiosInstance, AxiosResponse } from 'axios';

declare module 'axios' {
  interface AxiosResponse<T = any> extends Promise<T> {}
}

export abstract class HttpClient {
  protected readonly instance: AxiosInstance;

  public constructor(baseURL: string) {
    this.instance = axios.create({ baseURL });
    this.initializeResponseInterceptor();
  }

  private initializeResponseInterceptor = () => {
    this.instance.interceptors.response.use(
      this.handleResponse,
      this.handleError,
    );
  };

  private handleResponse = (res: AxiosResponse): any => {
    return res.data;
  };

  private handleError = (error: AxiosError) => {
    if (
      error.response?.status === 401 &&
      process.env.FORCE_UNAUTHORIZED_REDIRECT
    ) {
      const currentUrl = window.location.href;
      window.location.href = `/login?returnUrl=${encodeURIComponent(currentUrl)}`;
    }

    return Promise.reject(error);
  };
}
