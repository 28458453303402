/* eslint-disable no-console */
import React, {
  FC,
  useEffect,
  useState,
  useLayoutEffect,
  useRef,
  memo,
  useMemo,
  useCallback,
} from 'react';
import { Prompt, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { Form, Tabs, message } from 'antd';
import _ from 'lodash';
import toast, { Toaster } from 'react-hot-toast';
import { TopNavbar } from 'src/components/TopNavbar';
import {
  DocumentStatus,
  RootState,
  SelectedDocumentDraftState,
  TemplateLoader,
  TemplateProcessingState,
  TemplateState,
} from 'src/store/types';
import {
  getAssessmentById,
  getSelectedDocumentDraft,
  getAssessmentTypes,
  getTemplateFoldersOptions,
  getTemplateById,
  getLoadedTemplate,
  getTemplateLoader,
  getSelectedDocumentLoader,
  getThreadsByRefKey,
} from 'src/store/selectors';
import {
  documentDraftLoadRequest,
  documentDraftUpdateRequest,
} from 'src/store/actions/documentDraft';
import { threadsLoadRequest } from 'src/store/actions/threadListing';
import { TemplateEditor } from 'src/features/risk-assessment/components/TemplateEditor/TemplateEditor';
import { TextWrapper } from 'src/features/risk-assessment/routes/DocumentDraftPage/TextWrapper';
import { useAuth } from 'src/hooks/useAuth/useAuth';
import { companyLoadRequest } from 'src/store/actions/company';
import { companiesLoadRequest } from 'src/store/actions/companyListing';
import { SlidingPanel } from 'src/components/SlidingPanel';
import { FormCreateTemplate } from 'src/features/risk-assessment/components/FormCreateTemplate';
import { confirmDialog } from 'src/shared/utils';
import { templatesLoadRequest } from 'src/store/actions/templateListing';
import { assessmentTypesLoadAllRequest } from 'src/store/actions/assessmentTypes';
import { Button } from 'src/components/Button';
import MDEditor from '@uiw/react-md-editor';
import { questionsLoadRequest } from 'src/store/actions/questionListing';
import {
  templateLoadRequest,
  templateUpdateRequest,
} from 'src/store/actions/template';
import { documentPPSaveRequest } from 'src/store/actions/document';
import { commentShowSelectedComment } from 'src/store/actions/comment';
import { constantsLoadRequest } from 'src/store/actions/constantListing';
import { SideDialogBox } from 'src/components/SideDialogBox';
import rehypeRewrite from 'rehype-rewrite';
import { LoaderComponent } from 'src/components/Loader';

enum Tab {
  EDIT_TEMPLATE = '1',
  PREVIEW = '2',
}

interface RouteParams {
  id: string;
  assessmentId: string;
  documentId: string;
  documentDraftId: string;
}

interface TabsContainerProps {
  hiddenTabs: boolean;
}

interface MDPositionCoordinate {
  line: number;
  column: number;
  offset: number;
}

interface MDPositionNode {
  end: MDPositionCoordinate;
  start: MDPositionCoordinate;
}

interface MDEditorNode {
  type: string;
  children?: MDEditorNode[];
  value?: string;
  url?: string;
  position?: MDPositionNode;
}

const StyleOverride = styled.div`
  width: 100%;
  height: 100%;
  & ::selection {
    background-color: rgb(24 144 255 / 40%);
    color: white;
  }

  .center {
    text-align: center;
  }
`;

const PageContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 22px;
`;

const MainColumn = styled.div`
  width: calc(99% - ${({ theme }) => `${theme.sidebar.width}px`});
`;

const TabsContainer = styled.div<TabsContainerProps>`
  .ant-tabs-card {
    .ant-tabs-nav {
      .ant-tabs-nav-list {
        display: ${({ hiddenTabs }) => (hiddenTabs ? 'none' : 'block')};
      }
    }

    .ant-tabs-content-holder {
      height: 76vh;
    }

    .ant-tabs-content {
      height: 100%;

      margin-top: -16px;
      .ant-tabs-tabpane {
        height: 100%;
        padding: 16px;
        background: #fff;
      }
    }

    .ant-tabs-tab {
      background: transparent;
      border-color: transparent;
    }

    .ant-tabs-tab-active {
      background: #fff;
    }
  }

  .w-md-editor {
    height: 100%;
  }

  .w-md-editor-content {
    height: 100%;
    padding: 10px;
  }
`;

const REFRESH_TEMPLATE_TOAST_ID = 'refresh-template-toast-id';

export const DocumentDraftPage: FC<unknown> = memo(() => {
  const { user, isAdmin, isOfficer, isCompanyModerator } = useAuth();
  const dispatch = useDispatch();
  const {
    id: rawCompanyId,
    assessmentId: rawAssessmentId,
    documentId: rawDocumentId,
    documentDraftId,
  } = useParams<RouteParams>();

  const [hasLoadedTemplate, setHasLoadedTemplate] = useState(false);

  const companyId = Number(rawCompanyId);
  const assessmentId = Number(rawAssessmentId);
  const documentId = Number(rawDocumentId);

  const [writeCommentPanelVisible, setWriteCommentPanelVisible] =
    useState(false);
  const [selectedCommentId, setSelectedCommentId] = useState<
    number | undefined
  >(undefined);
  const [selectedThreadId, setSelectedThreadId] = useState<number | undefined>(
    undefined,
  );
  const [selectedRefKey, setSelectedRefKey] = useState<string | undefined>(
    undefined,
  );
  const [selectedLine, setSelectedLine] = useState<number | undefined>(
    undefined,
  );
  const [hoveredLine, setHoveredLine] = useState<number | undefined>(undefined);
  const [formPanelVisible, setFormPanelVisible] = useState<boolean>(false);
  const [pristine, setPristine] = useState<boolean>(true);
  const [templateValue, setTemplateValue] = useState<string>(' ');
  const [isSavingPPDocument, setIsSavingPPDocument] = useState<boolean>(false);
  const [isSavingTemplate, setIsSavingTemplate] = useState(false);
  const [source, setSource] = useState('');
  const [hasConflicts, setHasConflicts] = useState<boolean>(false);
  const [refKeysByLine, setRefKeysByLine] = useState<{ [k: number]: string }>(
    {},
  );
  const [selectedTab, setSelectedTab] = useState<Tab>(Tab.PREVIEW);
  const [editorScrollTop, setEditorScrollTop] = useState<number>(0);
  const [previewScrollTop, setPreviewScrollTop] = useState<number>(0);
  const [adjustPreviewScroll, setAdjustPreviewScroll] =
    useState<boolean>(false);
  const [adjustEditorScroll, setAdjustEditorScroll] = useState<boolean>(true);
  const [replyingToCommentId, setReplyingToCommentId] = useState<
    number | undefined
  >(undefined);
  const [isPageReady, setPageReady] = useState<boolean>(true);

  const { documentDraft } = useSelector(
    (state: RootState): SelectedDocumentDraftState =>
      getSelectedDocumentDraft(state),
  );
  const documentLoader = useSelector((state: RootState) =>
    getSelectedDocumentLoader(state),
  );
  const documentDraftTemplate = useSelector(
    (state: RootState): TemplateState =>
      getTemplateById(
        state,
        documentDraft.templateId ? String(documentDraft.templateId) : '',
      ),
  );
  const selectedTemplate = useSelector(
    (state: RootState): TemplateState => getLoadedTemplate(state),
  );
  const assessmentTypes = useSelector(getAssessmentTypes);
  const templateFolders = useSelector(getTemplateFoldersOptions);
  const assessment = useSelector((state: RootState) =>
    getAssessmentById(state, assessmentId),
  );
  const templateLoader = useSelector(
    (state: RootState): TemplateLoader => getTemplateLoader(state),
  );
  const threadsByRefKey = useSelector((state: RootState) =>
    getThreadsByRefKey(state),
  );

  const [templateForm] = Form.useForm();

  const { TabPane } = Tabs;
  let refreshTemplateTimeout: NodeJS.Timeout;
  const isRefreshingTemplate = useRef(false);

  const isTemplateProcessing =
    selectedTemplate.processingStatus === TemplateProcessingState.PROCESSING;

  useEffect(() => {
    const isDocumentLoaded = documentLoader.loading === false;
    const isTemplateLoaded = templateLoader.loading === false;

    if (hasLoadedTemplate && isDocumentLoaded && isTemplateLoaded) {
      setPageReady(true);
    } else {
      setPageReady(false);
    }
  }, [
    dispatch,
    documentLoader.loading,
    hasLoadedTemplate,
    templateLoader.loading,
  ]);

  useEffect(() => {
    if (user) {
      if (isCompanyModerator) {
        dispatch(companiesLoadRequest());
      }

      if (isAdmin) {
        dispatch(constantsLoadRequest());
      }
    }
  }, [dispatch]);

  useEffect(() => {
    if (
      selectedTemplate.processingStatus == TemplateProcessingState.PROCESSING
    ) {
      isRefreshingTemplate.current = true;
      toast.loading('Updating documents, this may take a few minutes', {
        id: REFRESH_TEMPLATE_TOAST_ID,
      });
      refreshTemplateTimeout = setTimeout(() => {
        dispatch(templateLoadRequest(selectedTemplate.id));
      }, 3000);
    } else if (isRefreshingTemplate.current) {
      setTimeout(() => {
        dispatch(documentDraftLoadRequest(documentDraftId, true));
      }, 1000);
      isRefreshingTemplate.current = false;
      toast.success('All documents updated', { id: REFRESH_TEMPLATE_TOAST_ID });
      clearTimeout(refreshTemplateTimeout);
    }
    return () => refreshTemplateTimeout && clearTimeout(refreshTemplateTimeout);
  }, [selectedTemplate]);

  useEffect(() => {
    const sourceBody =
      documentDraft.status === DocumentStatus.ACTIVE
        ? documentDraft?.previewBody || documentDraft?.body
        : documentDraft?.body;

    setSource(sourceBody || '');
    if (!hasLoadedTemplate && documentDraft.templateId) {
      setHasLoadedTemplate(true);
      dispatch(templateLoadRequest(documentDraft.templateId));
    }
  }, [documentDraft]);

  useEffect(() => {
    dispatch(threadsLoadRequest({ documentDraftId: Number(documentDraftId) }));
    dispatch(documentDraftLoadRequest(documentDraftId, true));
  }, [dispatch, documentDraftId]);

  useEffect(() => {
    if (assessment?.companyId) {
      // Need to know all the users from this company
      dispatch(companyLoadRequest(assessment.companyId));
    }
  }, [dispatch, assessment?.companyId]);

  useEffect(() => {
    if (documentDraftTemplate?.id) {
      if (documentDraftTemplate?.assessmentType) {
        templateForm.setFieldsValue({
          assessmentTypeId: documentDraftTemplate.assessmentType.id,
        });
        dispatch(
          questionsLoadRequest(documentDraftTemplate.assessmentType?.id),
        );
      }
      setTemplateValue(documentDraftTemplate.body || ' ');
    } else if (user && isAdmin) {
      dispatch(templatesLoadRequest());
    }
  }, [dispatch, documentDraftTemplate?.id]);

  // I don't know why this is needed, so I commented it out
  // useEffect(() => {
  //   if (
  //     selectedTemplate?.id &&
  //     documentDraftTemplate &&
  //     documentDraftTemplate.id !== selectedTemplate.id
  //   ) {
  //     // dispatch(
  //     //   documentDraftUpdateRequest(documentDraft.id, {
  //     //     ...documentDraft,
  //     //     templateId: selectedTemplate.id,
  //     //   }),
  //     // );
  //   }
  // }, [
  //   dispatch,
  //   documentDraft,
  //   documentDraftTemplate,
  //   selectedTemplate,
  //   selectedTemplate.id,
  // ]);

  // Prevent the user to reload or quit if there are unsaved changes
  useEffect(() => {
    const preventNavigationHandler = (e: Event) => {
      e.preventDefault();
      // TODO: Update deprecated returnValue
      e.returnValue = false;
    };

    if (!pristine) {
      window.addEventListener('beforeunload', preventNavigationHandler);
    }

    return () => {
      window.removeEventListener('beforeunload', preventNavigationHandler);
    };
  }, [pristine]);

  useEffect(() => {
    if (!isSavingTemplate) return;
    if (templateLoader.success) {
      void message.success('Template saved');
      setIsSavingTemplate(false);
      setPristine(true);
      setFormPanelVisible(false);
    }
    if (templateLoader.completed && templateLoader.error) {
      void message.error('Unable to save template');
      setIsSavingTemplate(false);
    }
  }, [templateLoader]);

  useEffect(() => {
    if (
      documentLoader.completed &&
      (documentLoader.success || documentLoader.error)
    ) {
      setIsSavingPPDocument(false);
    }
  }, [documentLoader]);

  // Auto-scroll preview tab
  useLayoutEffect(() => {
    if (!adjustPreviewScroll) return;
    if (selectedTab === Tab.PREVIEW) {
      const previewContainer = document.querySelector(
        '.wmde-markdown.wmde-markdown-color.w-md-editor-preview',
      );

      if (previewContainer) {
        previewContainer.scrollTop = Math.round(editorScrollTop * 1.1);
        setAdjustPreviewScroll(false);
      }
    } else if (selectedTab === Tab.EDIT_TEMPLATE) {
      const editorArea = document.querySelector('.ant-input.editor');

      if (editorArea) {
        editorArea.scrollTop = Math.round(previewScrollTop * 0.9);
        setAdjustEditorScroll(false);
      }
    }
  }, [selectedTab]);

  const handleSaveTemplate = useCallback(() => {
    if (templateValue) {
      setIsSavingTemplate(true);
      const templateObj: Partial<TemplateState> = {
        id: documentDraftTemplate.id,
        body: templateValue,
      };
      dispatch(templateUpdateRequest(documentDraftTemplate.id, templateObj));
    }
  }, [templateValue, setIsSavingTemplate, dispatch]);

  const generatePdfDocument = useCallback(() => {
    setIsSavingPPDocument(true);
    dispatch(documentPPSaveRequest(documentDraft, assessmentId));
  }, [setIsSavingPPDocument, dispatch]);

  const saveButton = useCallback(
    () => (
      <Button
        onClick={() => {
          confirmDialog({
            onOk: handleSaveTemplate,
            text: 'Updating your template and documents based on it may take a little time and we will email you when the template is ready.',
          });
        }}
        title="Save template"
        key={1}
        disabled={pristine || hasConflicts || isTemplateProcessing}
        loading={isSavingTemplate}
      />
    ),
    [
      pristine,
      hasConflicts,
      isTemplateProcessing,
      isSavingTemplate,
      handleSaveTemplate,
    ],
  );

  const saveAsButton = useCallback(
    () => (
      <Button
        onClick={() => {
          dispatch(assessmentTypesLoadAllRequest());
          dispatch(constantsLoadRequest());
          setFormPanelVisible(true);
        }}
        title="Save template as"
        key={2}
        disabled={hasConflicts || isTemplateProcessing}
      />
    ),
    [hasConflicts, isTemplateProcessing],
  );

  const getDocumentAdminButtons = useCallback(() => {
    const disableStartClientReviewButton =
      isTemplateProcessing ||
      documentDraft?.status === DocumentStatus.CLIENT_REVIEW ||
      documentDraft?.status === DocumentStatus.CLOSED;

    const disableFinalizeButton =
      isTemplateProcessing ||
      documentDraft?.status === DocumentStatus.CLIENT_REVIEW ||
      documentDraft?.status === DocumentStatus.ACTIVE ||
      documentDraft?.status === DocumentStatus.CLOSED;

    const isReviewingAgain = documentDraft?.status === DocumentStatus.ACCEPTED;

    const buttons = [
      <Button
        key={'document-draft-button-generate-pdf'}
        onClick={generatePdfDocument}
        title="Generate PDF document"
        loading={isSavingPPDocument}
        disabled={isTemplateProcessing}
      />,
      <Button
        title={
          isReviewingAgain ? 'Start Client Review Again' : 'Start client review'
        }
        key={'document-draft-button-start-client-review'}
        disabled={disableStartClientReviewButton}
        onClick={() => {
          confirmDialog({
            text: 'Are you sure you want to start client review for this document?',
            onOk: () => {
              dispatch(
                documentDraftUpdateRequest(documentDraftId, {
                  status: DocumentStatus.CLIENT_REVIEW,
                }),
              );
            },
          });
        }}
      />,
      <Button
        title="Finalize"
        key={'document-draft-button-finalize'}
        disabled={disableFinalizeButton}
        onClick={() => {
          confirmDialog({
            text: 'Are you sure you want to close this document draft?',
            onOk: () => {
              dispatch(
                documentDraftUpdateRequest(documentDraftId, {
                  status: DocumentStatus.CLOSED,
                }),
              );
            },
          });
        }}
      />,
    ];

    return buttons;
  }, [
    generatePdfDocument,
    isSavingPPDocument,
    isTemplateProcessing,
    documentDraft?.status,
  ]);

  const getOfficerButtons = () => {
    const buttons = [];

    buttons.push(
      <Button
        key={'document-draft-button-generate-pdf'}
        onClick={generatePdfDocument}
        title="Generate PDF document"
        loading={isSavingPPDocument}
        disabled={
          isTemplateProcessing || documentDraft.status !== DocumentStatus.CLOSED
        }
      />,
      <Button
        title="Mark as Completed"
        key={'mark-as-completed-button'}
        disabled={documentDraft.status !== DocumentStatus.CLIENT_REVIEW}
        onClick={() => {
          confirmDialog({
            text: 'Are you sure? By finishing your review you are notifying Total HIPAA that you are ready for your comments and questions to be reviewed.',
            onOk: () => {
              dispatch(
                documentDraftUpdateRequest(documentDraftId, {
                  status: DocumentStatus.ACCEPTED,
                }),
              );
            },
          });
        }}
      />,
    );

    return buttons;
  };

  const handleStartNewDiscussion = useCallback(
    (refKey?: string) => {
      setReplyingToCommentId(undefined);
      setSelectedThreadId(undefined);
      setSelectedCommentId(undefined);
      setSelectedRefKey(refKey);
      setWriteCommentPanelVisible(true);
    },
    [
      setReplyingToCommentId,
      setSelectedThreadId,
      setSelectedCommentId,
      setSelectedRefKey,
      setWriteCommentPanelVisible,
    ],
  );

  const handleSetSelectedRefKey = useCallback(
    (refKey?: string) => {
      if (refKey) {
        setSelectedRefKey(refKey);
        setSelectedThreadId(
          threadsByRefKey[refKey] ? threadsByRefKey[refKey].id : undefined,
        );
        dispatch(commentShowSelectedComment(`thread${refKey}`));
      }
    },
    [threadsByRefKey, dispatch],
  );

  const handleReply = useCallback(
    (parentId: number, replyingToId: number) => {
      setReplyingToCommentId(replyingToId);
      setSelectedCommentId(parentId);
      setWriteCommentPanelVisible(true);
    },
    [setReplyingToCommentId, setSelectedCommentId, setWriteCommentPanelVisible],
  );

  const addThreadToken = useCallback(() => {
    if (selectedRefKey && selectedLine && source.length >= selectedLine) {
      const sourceLines = source.split('\n');

      const space = String.fromCharCode(32);
      sourceLines[selectedLine - 1] =
        `${sourceLines[selectedLine - 1]}[THREAD#${selectedRefKey}]${space}${space}`;
      const updatedSource = sourceLines.join('\n');

      setSource(updatedSource);

      dispatch(
        documentDraftUpdateRequest(documentDraftId, {
          ...documentDraft,
          body: updatedSource,
        }),
      );
    }
  }, [selectedRefKey, selectedLine, source, setSource, dispatch]);

  const handleSaveButtonPress = useCallback(() => {
    setIsSavingTemplate(true);
    templateForm.submit();
  }, [setIsSavingTemplate, templateForm]);

  const handleTemplateChange = useCallback(
    (newValue?: string): void => {
      if (newValue) {
        setPristine(false);
        setTemplateValue(newValue);
      }
    },
    [setPristine, setTemplateValue],
  );

  const saveEditorScrollPosition = useCallback(() => {
    const editorArea = document.querySelector('.ant-input.editor');

    if (editorArea) {
      setEditorScrollTop(editorArea.scrollTop);
      setAdjustPreviewScroll(true);
    }
  }, [setEditorScrollTop, setAdjustPreviewScroll]);

  const savePreviewScrollPosition = useCallback(() => {
    const previewContainer = document.querySelector(
      '.wmde-markdown.wmde-markdown-color.w-md-editor-preview',
    );

    if (previewContainer) {
      setPreviewScrollTop(previewContainer.scrollTop);
      setAdjustEditorScroll(true);
    }
  }, [setPreviewScrollTop, setAdjustEditorScroll]);

  const handleTabChange = useCallback(
    (value: string) => {
      if (value === Tab.PREVIEW) {
        saveEditorScrollPosition();
      }

      if (value === Tab.EDIT_TEMPLATE) {
        savePreviewScrollPosition();
      }

      setSelectedTab(value as Tab);
    },
    [saveEditorScrollPosition, savePreviewScrollPosition, setSelectedTab],
  );

  const renderers = useMemo(
    () => ({
      text: (props: { value: string; nodeKey: string }) => {
        const { value, nodeKey } = props;

        const threadTokenRe = /\[THREAD#(?<key>[a-zA-Z0-9-]+)]/;

        const centerTextRe = /\[center](?<content>[^\[\]]*)\[\/center]/;

        const underlineTextRe = /\[u](?<content>[^\[\]]*)\[\/u]/g;

        const highlightTextRe =
          /\[highlight](?<content>[^\[\]]*)\[\/highlight]/g;

        const positionRE =
          /(?<type>\w+)-(?<line>[0-9]*)-(?<column>[0-9]*)-(?<offset>[0-9]*)/;

        // eslint-disable-next-line @typescript-eslint/prefer-regexp-exec
        const lineMatch = nodeKey.match(positionRE)?.groups as { line: string };

        const lineNumber = Number(lineMatch.line);

        const classNames: string[] = [];

        let processedText = value;

        processedText = processedText.replace(threadTokenRe, (_match, key) => {
          if (!refKeysByLine[lineNumber]) {
            refKeysByLine[lineNumber] = key;
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
            _.debounce(() => setRefKeysByLine(refKeysByLine), 400);
          }

          return '';
        });

        processedText = processedText.replace(
          centerTextRe,
          (_match, content: string) => {
            classNames.push('center');
            return content;
          },
        );

        processedText = processedText.replaceAll(
          underlineTextRe,
          (_: string, content: string) => {
            return `<u>${content}</u>`;
          },
        );

        processedText = processedText.replaceAll(
          highlightTextRe,
          (_: string, content: string) => {
            const shouldHighlightText =
              documentDraft.status === DocumentStatus.ACTIVE;
            const className = shouldHighlightText ? 'highlight-token' : '';
            return `<span class="${className}">${content}</span>`;
          },
        );

        return (
          <TextWrapper
            value={processedText}
            classNames={classNames}
            lineNumber={lineNumber}
            refKeysByLine={refKeysByLine}
            handleStartNewDiscussion={handleStartNewDiscussion}
            setSelectedRefKey={handleSetSelectedRefKey}
            setSelectedLine={setSelectedLine}
            setHoveredLine={setHoveredLine}
            selectedLine={selectedLine}
            selectedRefKey={selectedRefKey}
            hoveredLine={hoveredLine}
          />
        );
      },
    }),
    [
      handleStartNewDiscussion,
      handleSetSelectedRefKey,
      setSelectedLine,
      setHoveredLine,
      selectedLine,
      selectedRefKey,
      hoveredLine,
      documentDraft.status,
    ],
  );

  const handleFormClose = useCallback(() => {
    setWriteCommentPanelVisible(false);
    setSelectedLine(undefined);
    setSelectedRefKey(undefined);
  }, [setWriteCommentPanelVisible, setSelectedLine, setSelectedRefKey]);

  const resetStates = () => {
    setHasLoadedTemplate(false);
    setIsSavingPPDocument(false);
    setIsSavingTemplate(false);
    setHasConflicts(false);
    setRefKeysByLine({});
    setSelectedTab(Tab.PREVIEW);
    setEditorScrollTop(0);
    setPreviewScrollTop(0);
    setAdjustPreviewScroll(false);
    setAdjustEditorScroll(true);
    setReplyingToCommentId(undefined);
    setSelectedThreadId(undefined);
    setSelectedCommentId(undefined);
    setSelectedRefKey(undefined);
    setWriteCommentPanelVisible(false);
    setSelectedLine(undefined);
    setHoveredLine(undefined);
    setFormPanelVisible(false);
    setPristine(true);
    setTemplateValue(' ');
    setSource('');
    setPageReady(true);
  };

  if (!isPageReady) {
    return <LoaderComponent />;
  }

  return (
    <>
      <Prompt
        when={!pristine}
        message={'You have unsaved changes. Are you sure you want to leave?'}
      />
      <TopNavbar
        title={documentDraft?.title || `Document draft #${documentDraftId}`}
        prevRoute={{
          name: 'Documents Drafts',
          url: `/companies/${companyId}/assessments/${assessmentId}/assessmentReports/${documentId}/documentDrafts`,
        }}
        onBackCallback={resetStates}
        extraOptions={
          isAdmin
            ? selectedTab === Tab.EDIT_TEMPLATE
              ? [saveButton(), saveAsButton()]
              : [getDocumentAdminButtons()]
            : isOfficer
              ? [getOfficerButtons()]
              : []
        }
        withCompany
      />
      <PageContentContainer>
        <MainColumn>
          <TabsContainer hiddenTabs={!isAdmin}>
            <Tabs
              type="card"
              defaultActiveKey={Tab.PREVIEW}
              onTabClick={handleTabChange}
              destroyInactiveTabPane
            >
              {isAdmin && (
                <TabPane
                  tab="Edit template"
                  key="1"
                  disabled={isSavingPPDocument}
                >
                  {templateValue && documentDraftTemplate ? (
                    <TemplateEditor
                      source={templateValue || ' '}
                      templateId={documentDraftTemplate.id}
                      errorMessage={templateLoader.compilationError}
                      onChange={handleTemplateChange}
                      setHasConflicts={setHasConflicts}
                      isTemplateProcessing={isTemplateProcessing}
                    />
                  ) : (
                    <LoaderComponent />
                  )}
                </TabPane>
              )}
              <TabPane tab="Document Draft" key="2" disabled={isSavingTemplate}>
                <StyleOverride>
                  <MDEditor
                    hideToolbar
                    preview="preview"
                    value={source}
                    previewOptions={{
                      renderers,
                      disallowedTypes: ['link'],
                      unwrapDisallowed: true,
                      plugins: [
                        [
                          rehypeRewrite as any,
                          {
                            rewrite: (
                              node: MDEditorNode,
                              index: any,
                              parent: any,
                            ) => {
                              const processNode = (
                                node: MDEditorNode,
                                level = 0,
                                value = '',
                              ) => {
                                // Base Case 1
                                if (!node) {
                                  return '';
                                }

                                // Base Case 2
                                if (node.type === 'text') {
                                  return node.value || '';
                                }

                                if (!node.children) {
                                  return node.value || '';
                                }

                                // Recursive Case
                                node.children.forEach((child: MDEditorNode) => {
                                  value += processNode(child, level + 1);

                                  if (node.type === 'strong') {
                                    value = `<b>${value || ''}</b>` || '';
                                  }

                                  if (node.type === 'emphasis') {
                                    value = `<i>${value || ''}</i>` || '';
                                  }
                                });

                                return value;
                              };
                              if (node.type === 'paragraph') {
                                const newNode: MDEditorNode = {
                                  type: 'text',
                                  value: '',
                                  position: node.position,
                                };
                                newNode.value = processNode(node);
                                newNode.position = node.position;
                                node.children = [newNode];
                              }
                            },
                          },
                        ],
                      ],
                    }}
                    height="100%"
                  />
                </StyleOverride>
              </TabPane>
            </Tabs>
          </TabsContainer>
        </MainColumn>
        <SideDialogBox
          documentDraftId={documentDraft?.id}
          threadId={selectedThreadId}
          refKey={selectedRefKey}
          writeCommentPanelVisible={writeCommentPanelVisible}
          selectedCommentId={selectedCommentId}
          replyingToCommentId={replyingToCommentId}
          companyId={companyId}
          documentId={documentId}
          setSelectedThreadId={setSelectedThreadId}
          setSelectedLine={setSelectedLine}
          setSelectedRefKey={setSelectedRefKey}
          handleReply={handleReply}
          onSave={addThreadToken}
          handleStartNewDiscussion={handleStartNewDiscussion}
          onClose={handleFormClose}
          onClosePanel={() => setWriteCommentPanelVisible(false)}
          areThreadsWithoutQuestion
          documentDraftStatus={documentDraft.status}
          refKeysByLine={refKeysByLine}
        />
        <SlidingPanel
          title="Save template as"
          visible={formPanelVisible}
          saveLabel="Create template"
          onClose={() => {
            setFormPanelVisible(false);
            templateForm.resetFields();
          }}
          onSave={handleSaveButtonPress}
        >
          <FormCreateTemplate
            templateForm={templateForm}
            assessmentTypes={assessmentTypes}
            parentTemplateId={
              documentDraftTemplate ? documentDraftTemplate.id : null
            }
            templateFolders={templateFolders}
            showAdvancedOptionsCheckbox
            parentTemplateBody={templateValue}
          />
        </SlidingPanel>
        <Toaster />
      </PageContentContainer>
    </>
  );
});
